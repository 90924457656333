<template>
  <div v-if="ready" class="h-screen">
    <div class="h-full bg-black">
      <div class="h-full flex flex-col text-white justify-center">
        <div class="search-block flex items-center">
          <div class="w-4/6 flex flex-col items-center">
            <div class="flex">
              <div class="word flex items-center justify-center">我</div>
              <div class="word flex items-center justify-center">的</div>
              <div class="word flex items-center justify-center">年</div>
              <div class="word flex items-center justify-center">運</div>
              <div class="word flex items-center justify-center">勢</div>
            </div>
            <div class="range">
              可查詢{{ years[0] }}年~{{ years[years.length - 1] }}年
            </div>
          </div>
          <div class="w-2/6 flex flex-col items-center">
            <div
              class="btn-filter good-year flex justify-center items-center"
              :class="{
                active: selectedType == 'good',
                inactive: selectedType == 'bad',
              }"
              @click="selectType('good')"
            >
              好運年
            </div>
            <div
              class="btn-filter bad-year flex justify-center items-center"
              :class="{
                active: selectedType == 'bad',
                inactive: selectedType == 'good',
              }"
              @click="selectType('bad')"
            >
              壞運年
            </div>
          </div>
        </div>
        <div class="bg-main">
          <div
            class="h-full flex flex-row flex-wrap items-center justify-center"
          >
            <div
              v-for="year in years"
              class="item-year"
              @click="selectYear(year)"
            >
              <div
                class="age text-center"
                :class="{
                  currentYear: year == currentYear,
                  selectedYear: year == selectedYear,
                  inactive: isYearInactive(year),
                }"
              >
                <template v-if="year == currentYear">今年</template>
                <template v-else>{{ year - userYear }}歲</template>
              </div>
              <div
                class="year-ring flex items-center justify-center"
                :class="{
                  currentYear: year == currentYear,
                  selectedYear: year == selectedYear,
                }"
              >
                <div
                  class="year flex items-center justify-center"
                  :class="{
                    good: result[year].light == 'r1',
                    bad: result[year].light == 'r2',
                    inactive: isYearInactive(year),
                    currentYear: year == currentYear,
                  }"
                >
                  {{ year }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=""></div>
      </div>
    </div>
    <div
      v-if="cardUrl"
      class="
        h-full
        w-full
        absolute
        z-10
        top-0
        bg-black
        flex flex-col
        items-center
        justify-center
      "
    >
      <div class="rounded-full bg-white self-end mr-2" @click="closeImage()">
        <mdicon name="close" width="30" height="30" />
      </div>
      <div><img :src="cardUrl" alt="" /></div>
    </div>
    <notifyModal
      :popID="11"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-1.png'],
      }"
    ></notifyModal>
  </div>
</template>

<script>
import notifyModal from "@/components/notifyModal.vue";
import dayjs from "dayjs";
import Util from "@/mixins/util";
export default {
  name: "GoodluckYear",
  mixins: [Util],
  components: { notifyModal },
  data() {
    return {
      ready: false,
      liffId: "1656896964-xmZAAwlj",
      title: "",
      selectedType: null,
      selectedYear: null,
      userYear: null,
      currentYear: null,
      result: null,
      cardUrl: null,
      years: [],
    };
  },
  async created() {
    await this.setLiff(this.liffId);

    let years = [];
    let currentYear = dayjs().get("year");

    years.push(currentYear);
    for (let i = 1; i <= 2; i++) {
      years.unshift(currentYear - i);
    }
    for (let i = 1; i <= 12; i++) {
      years.push(currentYear + i);
    }
    this.years = years;
    this.currentYear = currentYear;
    this.selectedYear = currentYear;

    await this.init();
  },
  methods: {
    async init() {
      let data = await this.getData();
      this.userYear = data.birth_year;
      let result = {};
      data.data.forEach((row) => {
        result[row.year] = row;
      });
      this.result = result;
      this.ready = true;
    },
    closeImage() {
      this.cardUrl = null;
    },
    async closeCheckbox() {
      this.hasNotified = true;
      if (this.checkbox) {
        this.setState(1);
      }
    },
    async getData() {
      let url = `${this.apiURL}/detailed_murphy/lineID/${this.$store.state.profile.userId}/year`;
      let data = await fetch(url);
      data = await data.json();
      return data;
    },
    selectType(type) {
      if (this.selectedType == type) {
        this.selectedType = null;
        return;
      }
      if (
        this.selectedYear &&
        type == "good" &&
        this.result[this.selectedYear].light == "r2"
      )
        this.selectedYear = null;
      if (
        this.selectedYear &&
        type == "bad" &&
        this.result[this.selectedYear].light == "r1"
      )
        this.selectedYear = null;
      this.selectedType = type;
    },
    selectYear(year) {
      if (this.selectedType == "good" && this.result[year].light == "r2")
        return;
      if (this.selectedType == "bad" && this.result[year].light == "r1") return;
      this.selectedYear = year;
      this.cardUrl = this.result[year].cardUrl;
    },
    isYearInactive(year) {
      if (!this.selectedType) return false;
      if (this.selectedType == "good" && this.result[year].light == "r2")
        return true;
      if (this.selectedType == "bad" && this.result[year].light == "r1")
        return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-line {
  border: 1px solid red;
}
.search-block {
  padding: 12px;
  margin: 0px 20px;
  margin-bottom: 30px;
  border-radius: 30px;
  border: 0px solid #00ffff;
  box-shadow: 0px 0px 16px #00ffff;

  background-color: #1a1b22;
  .word {
    color: #00ffff;
    font-weight: bolder;
    border: 1px solid #00ffff;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    margin: 0 2px;
    font-size: 20px;
  }
  .range {
    color: #808080;
    padding-top: 10px;
    font-size: 18px;
  }
  .btn-filter {
    font-family: "Noto Sans TC", sans-serif !important;
    border-radius: 30px;
    padding: 5px 15px;
    margin: 8px;
    font-size: 14px;
    font-weight: 600;
    &.good-year {
      background-color: #fbe4dc;
      color: #f87171;
      margin-bottom: 2px;
      &.active {
        background-color: #cd6161;
        color: #fff;
      }
    }
    &.bad-year {
      background-color: #d7d7d7;
      color: #424242;
      &.active {
        background-color: #efefef;
        color: #000;
      }
    }
    &.inactive {
      background-color: #808080;
      color: #161820;
    }
  }
}

.bg-main {
  background-color: #1a1c22;
  padding: 10px 0px;
  .item-year {
    flex: 0 0 20%;
    margin: 18px 0px;
    .age {
      font-size: 17px;
      color: #808080;

      &.inactive {
        color: #333333;
      }
      &.currentYear {
        color: #808080;
      }
      &.selectedYear {
        color: #fff;
      }
    }
    .year-ring {
      margin: 0 auto;
      border: 2px solid #1a1c22;
      width: 62px;
      height: 62px;
      border-radius: 100%;
      &.currentYear {
        border-color: #808080;
      }
      &.selectedYear {
        border-color: #f15a24;
      }
    }
    .year {
      font-family: "Rubik", sans-serif !important;
      font-weight: 400;
      font-size: 18px;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      box-shadow: 2px 2px 2px 2px #000000de;

      &.good {
        color: #ef4444;
        background-color: #fbe4dc;
      }
      &.bad {
        color: #535353;
        background-color: #d7d7d7;
      }
      &.inactive {
        color: #333333;
        background-color: #1a1c22;
        &.currentYear {
          color: #808080;
        }
      }
    }
  }
}
</style>