import { render, staticRenderFns } from "./GoodluckYear.vue?vue&type=template&id=5379c30a&scoped=true&"
import script from "./GoodluckYear.vue?vue&type=script&lang=js&"
export * from "./GoodluckYear.vue?vue&type=script&lang=js&"
import style0 from "./GoodluckYear.vue?vue&type=style&index=0&id=5379c30a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5379c30a",
  null
  
)

export default component.exports