var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',{staticClass:"h-screen"},[_c('div',{staticClass:"h-full bg-black"},[_c('div',{staticClass:"h-full flex flex-col text-white justify-center"},[_c('div',{staticClass:"search-block flex items-center"},[_c('div',{staticClass:"w-4/6 flex flex-col items-center"},[_vm._m(0),_c('div',{staticClass:"range"},[_vm._v(" 可查詢"+_vm._s(_vm.years[0])+"年~"+_vm._s(_vm.years[_vm.years.length - 1])+"年 ")])]),_c('div',{staticClass:"w-2/6 flex flex-col items-center"},[_c('div',{staticClass:"btn-filter good-year flex justify-center items-center",class:{
              active: _vm.selectedType == 'good',
              inactive: _vm.selectedType == 'bad',
            },on:{"click":function($event){return _vm.selectType('good')}}},[_vm._v(" 好運年 ")]),_c('div',{staticClass:"btn-filter bad-year flex justify-center items-center",class:{
              active: _vm.selectedType == 'bad',
              inactive: _vm.selectedType == 'good',
            },on:{"click":function($event){return _vm.selectType('bad')}}},[_vm._v(" 壞運年 ")])])]),_c('div',{staticClass:"bg-main"},[_c('div',{staticClass:"h-full flex flex-row flex-wrap items-center justify-center"},_vm._l((_vm.years),function(year){return _c('div',{staticClass:"item-year",on:{"click":function($event){return _vm.selectYear(year)}}},[_c('div',{staticClass:"age text-center",class:{
                currentYear: year == _vm.currentYear,
                selectedYear: year == _vm.selectedYear,
                inactive: _vm.isYearInactive(year),
              }},[(year == _vm.currentYear)?[_vm._v("今年")]:[_vm._v(_vm._s(year - _vm.userYear)+"歲")]],2),_c('div',{staticClass:"year-ring flex items-center justify-center",class:{
                currentYear: year == _vm.currentYear,
                selectedYear: year == _vm.selectedYear,
              }},[_c('div',{staticClass:"year flex items-center justify-center",class:{
                  good: _vm.result[year].light == 'r1',
                  bad: _vm.result[year].light == 'r2',
                  inactive: _vm.isYearInactive(year),
                  currentYear: year == _vm.currentYear,
                }},[_vm._v(" "+_vm._s(year)+" ")])])])}),0)]),_c('div',{})])]),(_vm.cardUrl)?_c('div',{staticClass:"\n      h-full\n      w-full\n      absolute\n      z-10\n      top-0\n      bg-black\n      flex flex-col\n      items-center\n      justify-center\n    "},[_c('div',{staticClass:"rounded-full bg-white self-end mr-2",on:{"click":function($event){return _vm.closeImage()}}},[_c('mdicon',{attrs:{"name":"close","width":"30","height":"30"}})],1),_c('div',[_c('img',{attrs:{"src":_vm.cardUrl,"alt":""}})])]):_vm._e(),_c('notifyModal',{attrs:{"popID":11,"payload":{
      case: 2,
      cardUrl: _vm.$store.state.images['POP-1.png'],
    }}})],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"word flex items-center justify-center"},[_vm._v("我")]),_c('div',{staticClass:"word flex items-center justify-center"},[_vm._v("的")]),_c('div',{staticClass:"word flex items-center justify-center"},[_vm._v("年")]),_c('div',{staticClass:"word flex items-center justify-center"},[_vm._v("運")]),_c('div',{staticClass:"word flex items-center justify-center"},[_vm._v("勢")])])}]

export { render, staticRenderFns }